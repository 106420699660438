import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1`;

class TeamMemberService {
  getAll({ companyId, filters, ...query }) {
    return axios.get(`${API_URL}/company/${companyId}/team-member`, { params: { ...query, ...filters } }).then(({ data }) => data);
  }

  create(companyId, data) {
    return axios.post(`${API_URL}/company/${companyId}/team-member`, data).then(({ data }) => data);
  }

  updateById(companyId, memberId, data) {
    return axios.patch(`${API_URL}/company/${companyId}/team-member/${memberId}`, data).then(({ data }) => data);
  }

  resendInvitation(companyId, memberId) {
    return axios.post(`${API_URL}/company/${companyId}/team-member/${memberId}`).then(({ data }) => data);
  }

  deleteById(companyId, memberId) {
    return axios.delete(`${API_URL}/company/${companyId}/team-member/${memberId}`).then(({ data }) => data);
  }
}

export default new TeamMemberService();
