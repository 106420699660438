import teamMemberService from '../services/team-member.service';
import { actions } from '../contexts/alert/context';

const initialState = {
  users: [],
  userForDelete: null,
};

const teamMember = (set, get) => ({
  ...initialState,
  setUserForDelete: user => set(state => ({ teamMembers: { ...state.teamMembers, userForDelete: user } })),
  getUsers: async (filters = {}) => {
    set({ loading: true, error: null });

    const currentState = get();
    const { companyId } = currentState;
    const { skip, take } = currentState.table;

    try {
      const { users, total } = await teamMemberService.getAll({ companyId, skip, take, filters });

      set(state => ({
        teamMembers: { ...state.teamMembers, users },
        table: { ...state.table, total },
        loading: false,
      }));
    } catch (error) {
      set(state => ({ loading: false, error: error.message, table: { ...state.table } }));
    }
  },
  addUser: async ({ role, ...userData }) => {
    set({ loading: true, error: null });

    const currentState = get();
    const { companyId } = currentState;
    const { users } = currentState.teamMembers;

    try {
      const user = await teamMemberService.create(companyId, {
        ...userData,
        role: role.id,
      });

      set(state => ({
        teamMembers: { ...state.teamMembers, users: [user, ...users] },
        loading: false,
      }));

      actions.showAlert({
        type: 'success',
        message: `User ${user.firstName} ${user.lastName} added successfully`,
      });
    } catch (error) {
      set({ loading: false, error: error.message });

      throw error;
    }
  },
  updateUser: async ({ memberId, role, hasLeadPermissions }) => {
    set({ loading: true, error: null });

    const currentState = get();
    const { companyId } = currentState;

    try {
      await teamMemberService.updateById(companyId, memberId, { role: role.id, hasLeadPermissions });

      set(state => ({
        teamMembers: {
          ...state.teamMembers,
          users: state.teamMembers.users.map(item => (item.id === memberId ? { ...item, role: role.id, hasLeadPermissions } : item)),
        },
        loading: false,
      }));

      actions.showAlert({
        type: 'success',
        message: 'Permissions has been resent',
      });
    } catch (error) {
      set({ loading: false, error: error.message });

      throw error;
    }
  },
  resendInvitation: async memberId => {
    set({ loading: true, error: null });

    const currentState = get();
    const { companyId } = currentState;

    try {
      await teamMemberService.resendInvitation(companyId, memberId);

      set(state => ({
        teamMembers: {
          ...state.teamMembers,
          users: state.teamMembers.users.map(item =>
            item.id === memberId
              ? {
                  ...item,
                  invitation: {
                    ...item.invitation,
                    status: 'pending',
                  },
                }
              : item,
          ),
        },
        loading: false,
      }));

      actions.showAlert({
        type: 'success',
        message: 'Invitation has been resent',
      });
    } catch (error) {
      set({ loading: false, error: error.message });

      throw error;
    }
  },
  deleteUser: async () => {
    set({ loading: true, error: null });

    const currentState = get();
    const {
      companyId,
      teamMembers: { userForDelete },
    } = currentState;

    if (!userForDelete) return;

    try {
      await teamMemberService.deleteById(companyId, userForDelete.id);

      set(state => ({
        teamMembers: {
          ...state.teamMembers,
          users: state.teamMembers.users.filter(user => user.id !== userForDelete.id),
          userForDelete: null,
        },
        loading: false,
      }));

      actions.showAlert({
        type: 'success',
        message: `User ${userForDelete.firstName} ${userForDelete.lastName} deleted successfully`,
      });
    } catch (error) {
      set({ loading: false, error: error.message });

      throw error;
    }
  },

  clearStorage: () =>
    set(state => ({
      teamMembers: {
        ...state.teamMembers,
        users: [],
      },
    })),
});

export default teamMember;
