import create from 'zustand';
import { devtools } from 'zustand/middleware';

import teamMemberStore from './teamMember';
import tableStore from './table';
import permissionsStore from './permissions';

const initialState = {
  loading: false,
  error: null,
  companyId: null,
  user: null,
};

const store = create(
  devtools((set, get) => ({
    ...initialState,
    setUser: user => set({ user }),
    setCompanyId: companyId => set({ companyId }),
    teamMembers: teamMemberStore(set, get),
    table: tableStore(set, get),
    permissions: permissionsStore(set, get),
  })),
);

export default store;
