import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/v1/permissions`;

class PermissionService {
  getByPage(page) {
    return axios.get(`${API_URL}/${page}`).then(({ data }) => data);
  }
}

export default new PermissionService();
