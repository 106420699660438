import permissionService from '../services/permission.service';

const initialState = {
  request: null,
};

const permissionsStore = (set, get) => ({
  ...initialState,
  getPermissionsForPage: async page => {
    const { permissions } = get();

    if (permissions[page]) return;

    try {
      const permissions = await permissionService.getByPage(page);

      const permissionsObject = permissions.reduce((acc, item) => ({ ...acc, [item.entityTypeName]: item }), {});

      set(state => ({
        permissions: {
          ...state.permissions,
          [page]: permissionsObject,
        },
      }));
    } catch (error) {
      set(state => ({
        error: error.message,
        permissions: {
          ...state.permissions,
          [page]: null,
        },
      }));
    }
  },
  checkPermissions: (page, action, type = '') => {
    const { permissions, user } = get();

    if (page === 'request' && action === 'revoke' && user.role.includes('hunter')) return user.role === 'hunter-admin';

    if (user.role.includes('hunter')) return true;

    if (!permissions[page] || !Object.keys(permissions[page]).length) return false;

    if (page === 'request') {
      if (type === 'other benefits') type = 'other-benefits';
      if (type === 'sick days') type = 'sick-days';
    }

    const key = type ? `${page}-${type}` : page;

    return permissions[page]?.[key]?.[action] || false;
  },
});

export default permissionsStore;
