import typography from '../typography';

export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        row: {
          background: theme.palette.common.white,
          '&:hover': {
            background: theme.palette.light.primary.shades['8p'],
            cursor: 'pointer',
          },
          '&.Mui-warn': {
            border: `1px solid ${theme.palette.light.warning.light}`,
          },
        },
        columnHeaderTitle: {
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '0.93rem',
          letterSpacing: '0.025rem',
          color: theme.palette.light.neutral_grey['30p'],
        },
        cell: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '0.93rem',
          letterSpacing: '0.025rem',
          textTransform: 'capitalize',
          color: theme.palette.light.neutral_grey['50p'],
          '&.Mui-error, & .Mui-error': {
            backgroundColor: theme.palette.light.error.shades['30p'],
            outline: `1px solid ${theme.palette.light.error.shades['50p']}`,
          },
          '&.title-2': {
            ...typography.TITLE2,
            color: theme.palette.light.neutral_grey['90p'],
          },
          '&.lowercase': {
            textTransform: 'lowercase',
          },
          '& .MuiDataGrid-editInputCell': {
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '1.37375rem',
            letterSpacing: '0.00625rem',
            color: theme.palette.light.neutral_grey['90p'],
          },
          '& .MuiInputBase-root': {
            height: '100%',
          },
        },
        sortIcon: {
          color: theme.palette.light.primary.shades['90p'],
        },
        columnHeader: {
          background: theme.palette.common.white,
          '&:hover': {
            background: theme.palette.light.primary.shades['8p'],
            cursor: 'pointer',
          },
        },
      },
    },
  };
}
