const initialState = {
  page: 1,
  skip: 0,
  take: 10,
  total: 0,
};

const tableStore = (set, _get) => ({
  ...initialState,
  setPagination: ({ page, take, skip }) => set(state => ({ table: { ...state.table, page, take, skip } })),
  setTotal: total => set(state => ({ table: { ...state.table, total } })),
  clearStorage: () => set(state => ({ table: { ...initialState, ...state.table } })),
});

export default tableStore;
